<template>
  <div>
    <div class="cui__utils__heading">
      <strong>Контент: Новости</strong>
    </div>

    <div class="card">
      <div class="card-header card-header-flex">
        <div class="d-flex flex-column justify-content-center mr-auto">
          <h5 class="mb-0">Все новости</h5>
        </div>

        <div class="d-flex flex-column justify-content-center mr-2">
          <a-input ref="searchInput" v-model="search" placeholder="Поиск">
            <a-icon slot="prefix" type="search" />
            <a-tooltip slot="suffix" title="Поиск по названию, дате (пример: 2021-07-23)">
              <a-icon type="info-circle" style="color: rgba(0,0,0,.45)" />
            </a-tooltip>
          </a-input>
        </div>

        <div class="d-flex align-items-center ml-3">
          <a
            class="btn btn-secondary mr-2"
            :class="{ disabled: noSelectedRows }"
            :disabled="noSelectedRows"
            @click="bulkDelete"
          >
            Удалить
          </a>
          <a
            class="btn btn-primary"
            @click="$router.push({ path: `/content/news/0`})"
          >
            Добавить
          </a>
        </div>
      </div>

      <div class="card-body">
        <a-button class="ml-auto d-block mb-2" type="primary" @click="$router.push({ path: `/content/news/news_tags/list`})">
          Настроить рубрики новостей
        </a-button>

        <news-table
          :list="list"
          :paginate="pagination"
          @removeItem="removeItem"
          @handleTableChange="handleTableChange"
          @selectRows="selectedRowsIds = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NewsTable from '@/components/content/news/NewsTable'

export default {
  name: 'index',

  components: {
    NewsTable,
  },

  data() {
    return {
      selectedRowsIds: [],
      list: [],
      search: '',
      sort: null,
      pagination: {
        current: 1,
        total: 1,
        pageSize: 10,
      },
    }
  },

  created() {
    this.getList()
  },

  watch: {
    search() {
      this.pagination.current = null
      this.getList()
    },
  },

  computed: {
    noSelectedRows() {
      return this.selectedRowsIds.length === 0
    },

    queryParams() {
      return {
        page: this.pagination.current,
        search: this.search ? this.search : null,
        sort: this.sort ? this.sort : null,
      }
    },
  },

  methods: {
    async getList() {
      const params = this.queryParams

      const news = (await this.$services.get('admin/news', { params })).data.data.values

      this.list = news.data
      this.pagination = {
        current: news.pagination.current_page,
        total: news.pagination.total,
        pageSize: news.pagination.per_page,
      }
    },

    handleTableChange(pagination, _, sorter) {
      this.pagination.current = pagination.current
      this.sort = sorter.order
        ? `${sorter.field}|${sorter.order.replace('end', '')}`
        : null

      this.getList()
    },

    async removeItem(id) {
      try {
        await this.$services.delete(`admin/news/${id}`)
        await this.getList()
      } catch (e) {
        console.log(e)
      }
    },

    async bulkDelete() {
      await this.$services.delete('admin/deleteNewsById', {
        params: {
          news_id: this.selectedRowsIds,
        },
      })
      await this.getList()
    },
  },
}
</script>
